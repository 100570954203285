import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { DOMHelper } from 'Common/Helpers';
import { PhotoGrid } from 'Common/Elements';
import { useAlbumsEndpoint } from 'Endpoints';
import { AlbumDetails } from 'Models/AlbumModels';
import { Button, LinearProgress } from '@material-ui/core';
import { useAppState } from 'Context/AppProvider';
import { DeleteConfirmation } from 'Common/Modals';

export const Details = (props: RouteComponentProps<any>) => {
	const ep = useAlbumsEndpoint();
	const [album, setAlbum] = useState<AlbumDetails>();
	const state = useAppState();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isDeletedConfirmed, setIsDeleteConfirmed] = useState(false);

	useEffect(() => {
		DOMHelper.setPageTitle('Album Details');
		ep.GetAlbum(props.match.params.id).then(r => r && setAlbum(r));
	}, []);

	useEffect(() => {
		if (album && album.name !== '') {
			DOMHelper.setPageTitle(album.name);
		}
	}, [album]);

	useEffect(() => {
		if (isDeletedConfirmed && album) {
			ep.Delete(album.id)
				.then(r => r && props.history.push('/albums'))
				.finally(() => setIsDeleteConfirmed(false));
		}
	}, [isDeletedConfirmed]);

	if (ep.IsLoading || !album) {
		// TODO: make this look nicer
		return <LinearProgress />;
	}

	if (!state.CurrentUser) {
		return null;
	}

	return (
		<React.Fragment>
			{album.createdBy === state.CurrentUser.guid && (
				<Button style={{ float: 'right' }} onClick={() => setIsModalOpen(true)}>
					Delete
				</Button>
			)}
			<h2>{album.name}</h2>

			<PhotoGrid photos={album.photos} />

			<DeleteConfirmation
				label="Album"
				open={isModalOpen}
				closeModal={() => setIsModalOpen(false)}
				handleDelete={() => setIsDeleteConfirmed(true)}
				warningText="Are you sure? This will permanently delete the album and all its photos."
			/>
		</React.Fragment>
	);
};
