import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as Sentry from '@sentry/react';

import Layout from './Layout';
import { SnackbarProvider } from 'notistack';
import { useAppState } from 'Context/AppProvider';

const overallTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#43a047', // green
		},
		secondary: {
			main: '#55A1C1', // bluish-graying-tealish
		},
	},
	typography: {
		fontFamily: ['Roboto Condensed', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
	},
	overrides: {
		MuiDrawer: {
			paper: {
				backgroundColor: '#222D32',
				color: '#8aa4af',
			},
			paperAnchorDockedLeft: {
				height: '100vh',
			},
		},
		MuiDivider: {
			root: {
				backgroundColor: '#394C54',
			},
		},
		MuiListItemIcon: {
			root: {
				color: '#8aa4af',
			},
		},
		MuiAppBar: {
			colorPrimary: {
				backgroundColor: '#333',
			},
		},
	},
});

const FallbackComponent = () => {
	return <div>An error has occurred</div>;
};

export const App = () => {
	const state = useAppState();

	return (
		<Sentry.ErrorBoundary showDialog={true} fallback={<FallbackComponent />}>
			<BrowserRouter>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<ThemeProvider theme={overallTheme}>
						<SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
							<Layout isLoading={state.LoginStatus !== 'Logged In'} />
						</SnackbarProvider>
					</ThemeProvider>
				</MuiPickersUtilsProvider>
			</BrowserRouter>
		</Sentry.ErrorBoundary>
	);
};
