import React from 'react';
import { CollectionModels } from 'Models';
import { Link } from 'react-router-dom';
import { Grid, Card, CardMedia, CardContent, Typography, makeStyles, Theme } from '@material-ui/core';

export interface ICollectionGridProps {
	collections: ReadonlyArray<CollectionModels.Collection>;
}

const useStyles = makeStyles((theme: Theme) => ({
	cardMedia: {
		paddingTop: '56.25%', // 16:9
	},
	cardContent: {
		padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px !important`,
	},
}));

export const CollectionGrid = (props: ICollectionGridProps) => {
	const classes = useStyles();

	return (
		<Grid container={true}>
			{props.collections.map(c => (
				<Grid item={true} key={c.id} xs={12} sm={6} md={4} lg={3}>
					<Link to={'/collections/' + c.id}>
						<Card>
							<CardMedia image={c.photoUrl + '/500'} title={c.name} className={classes.cardMedia} />
							<CardContent className={classes.cardContent}>
								<Typography gutterBottom={true} variant="h5" component="h2">
									{c.name}
								</Typography>
							</CardContent>
						</Card>
					</Link>
				</Grid>
			))}
		</Grid>
	);
};
