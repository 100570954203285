import { PhotoOverview } from './PhotoModels';

/** album overview data used for returning both 'all' and 'my' albums */
export class Album {
	id = 0;
	name = '';
	description = '';
	/** The full Url to the album's main photo contents */
	photoUrl = '';
	photoId = 0;
}

export class AlbumDetails {
	id = 0;
	name = '';
	description = '';
	userName = '';
	photos: PhotoOverview[] = [];
	createdBy = '';
}

export class AddAlbum {
	name = '';
	description = '';
	isShared = false;
}
