import React, { useEffect, useState } from 'react';
import { CollectionGrid } from 'Collections';
import { DOMHelper } from 'Common/Helpers';

import { Button, Grid, Fade, Typography, LinearProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useCollectionsEndpoint } from 'Endpoints';
import { Collection } from 'Models/CollectionModels';

export const Home = () => {
	const ep = useCollectionsEndpoint();
	const [collections, setCollections] = useState<Collection[]>([]);

	useEffect(() => {
		DOMHelper.setPageTitle('My Collections');
		ep.GetMine().then(r => setCollections(r));
	}, []);

	if (ep.IsLoading) {
		// TODO: make this look nicer
		return <LinearProgress />;
	}

	if (collections.length === 0) {
		return (
			<div>
				<p>You have no saved collections.</p>
				<Link to="/collections/all">
					<Button type="button" variant="contained" color="primary">
						View All Collections
					</Button>
				</Link>
			</div>
		);
	}

	return (
		<Fade in={true}>
			<React.Fragment>
				<Grid container={true} justify="space-between">
					<Grid item={true}>
						<Typography variant="h3" gutterBottom={true}>
							My Collections
						</Typography>
					</Grid>
					<Grid item={true}>
						<Link to="/collections/all">
							<Button type="button" variant="contained" color="primary">
								View All Collections
							</Button>
						</Link>
					</Grid>
				</Grid>
				<CollectionGrid collections={collections} />
			</React.Fragment>
		</Fade>
	);
};
