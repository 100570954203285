import { dateSchema, numberSchema, stringSchema } from 'Common/Forms/BaseSchemas';
import { EditPhoto } from 'Models/PhotoModels';
import * as yup from 'yup';

export const EditPhotoSchema = yup.object().shape<EditPhoto>({
	id: numberSchema('Id'),
	title: stringSchema('Title'),
	description: stringSchema('Description'),
	credit: stringSchema('Credit'),
	takenOn: dateSchema('Taken On'),
});
