import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DOMHelper } from 'Common/Helpers';

import {
	Grid,
	Card,
	CardMedia,
	makeStyles,
	Theme,
	Fade,
	CardContent,
	Typography,
	LinearProgress,
} from '@material-ui/core';
import { useAlbumsEndpoint } from 'Endpoints';
import { Album } from 'Models/AlbumModels';

const useStyles = makeStyles((theme: Theme) => ({
	cardGrid: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8),
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	cardMedia: {
		paddingTop: '56.25%', // 16:9
	},
	cardContent: {
		flexGrow: 1,
	},
	footer: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(6),
	},
}));

export const Home = () => {
	const classes = useStyles();
	const ep = useAlbumsEndpoint();
	const [albums, setAlbums] = useState<Album[]>([]);

	useEffect(() => {
		DOMHelper.setPageTitle('Albums');
		ep.Get().then(r => setAlbums(r));
	}, []);

	if (ep.IsLoading) {
		// TODO: make this look nicer
		return <LinearProgress />;
	}

	return (
		<Fade in={true}>
			<React.Fragment>
				<Grid container={true} spacing={3}>
					{albums
						.filter(a => a.photoId !== null)
						.map(a => (
							<Grid item={true} key={a.id} xs={12} sm={6} md={4} lg={3}>
								<Card className={classes.card}>
									<Link to={'/albums/' + a.id}>
										<CardMedia
											className={classes.cardMedia}
											image={a.photoUrl + '/500'}
											title={a.name}
										/>
									</Link>
									<CardContent>
										<Typography gutterBottom={true} variant="h5" component="h2">
											{a.name}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
						))}
				</Grid>
			</React.Fragment>
		</Fade>
	);
};
