import { useNotifications } from 'Common/Notifications';
import { SearchResult } from 'Models/AppModels';
import { Endpoint } from './Endpoint';

export const useSearchEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('search');

	const Get = (term: string) =>
		ep.Get<SearchResult[]>(term).catch(() => {
			useError('Error getting search results');
			return [] as SearchResult[];
		});

	const Build = () =>
		ep
			.Post<number>(null, 'build')
			.then(r => {
				useSuccess('Search index built');
				return r;
			})
			.catch(() => {
				useError('Error building search index');
				return undefined;
			});

	return { Get, Build, IsLoading: ep.IsLoading };
};
