import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme, InputBase, Paper, Grid, Typography, ClickAwayListener } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { AppModels } from 'Models';
import { Link } from 'react-router-dom';
import { useSearchEndpoint } from 'Endpoints';
import { SearchResult } from 'Models/AppModels';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		maxWidth: 450,
		position: 'relative',
	},
	search: {
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		marginLeft: 0,
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto',
		},
	},
	searchIcon: {
		width: theme.spacing(9),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
		width: '100%',
	},
	inputInput: {
		paddingTop: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingLeft: theme.spacing(10),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: 200,
		},
	},
	results: {
		width: '100%',
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(3),
		position: 'absolute',
	},
	result: {
		padding: 4,
	},
	resultType: {
		textAlign: 'right',
		marginRight: 16,
	},
	resultBody: {
		borderLeft: 'solid 1px #ccc',
		padding: '8px 0 8px 12px',
		'&:hover': {
			backgroundColor: '#e9f1f9',
		},
	},
	seeAll: {
		borderTop: 'solid 1px #ccc',
		padding: '8px',
		'&:hover': {
			backgroundColor: '#e9f1f9',
		},
	},
	seeAllLink: {
		textDecoration: 'none',
		display: 'block',
	},
}));

const Result = (props: {
	searchResult: AppModels.SearchResult;
	onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}) => {
	const classes = useStyles();
	let url = '';
	switch (props.searchResult.type.toLowerCase()) {
		case 'photo':
			url = '/photos/' + props.searchResult.id;
			break;
		case 'album':
			url = '/albums/' + props.searchResult.id;
			break;
		case 'collection':
			url = '/collections/' + props.searchResult.id;
			break;
	}
	return (
		<Grid container={true} className={classes.result}>
			<Grid item={true} lg={3}>
				<Typography variant="subtitle1" color="textSecondary" component="p" className={classes.resultType}>
					{props.searchResult.type}
				</Typography>
			</Grid>
			<Grid item={true} lg={9} className={classes.resultBody}>
				<Link to={url} onClick={props.onClick}>
					<Typography variant="h6" component="h4">
						{props.searchResult.title}
					</Typography>
					<Typography component="p" variant="body2" color="textSecondary">
						{props.searchResult.description && props.searchResult.description.substring(0, 100)}
					</Typography>
				</Link>
			</Grid>
		</Grid>
	);
};

export const Search = () => {
	/** The minimum number of characters that must be typed in for the search to hit the API */
	const termMinimum = 2;
	const classes = useStyles();
	const [searchTerm, setSearchTerm] = useState('');
	const [showResults, setShowResults] = useState(false);
	const [results, setResults] = useState<SearchResult[]>([]);

	const ep = useSearchEndpoint();

	useEffect(() => {
		// use the event value since using the newly set state const ended up being one step/event behind
		if (searchTerm.length >= termMinimum) {
			// only do the search once the user's typed in at least two characters
			ep.Get(searchTerm).then(r => setResults(r));
			setShowResults(true);
		} else {
			setShowResults(false);
		}
	}, [searchTerm]);

	const handleClickAway = () => {
		setShowResults(false);
	};

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<Grid container={true} className={classes.root}>
				<Grid item={true} xs={12} className={classes.search}>
					<div className={classes.searchIcon}>
						<SearchIcon />
					</div>
					<InputBase
						placeholder="Search…"
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
						value={searchTerm}
						classes={{
							root: classes.inputRoot,
							input: classes.inputInput,
						}}
					/>
				</Grid>
				<Grid item={true} xs={12}>
					<Paper className={classes.results}>
						{showResults && (
							<React.Fragment>
								{results.slice(0, 5).map(r => {
									return (
										<Result
											searchResult={r}
											key={`${r.type}-${r.id}`}
											onClick={() => {
												setShowResults(false);
												setSearchTerm('');
											}}
										/>
									);
								})}
								{results.length ? (
									<Typography
										component="p"
										variant="body2"
										color="textSecondary"
										className={classes.seeAll}
									>
										<Link
											to={'/search/' + searchTerm}
											className={classes.seeAllLink}
											onClick={() => {
												setShowResults(false);
												setSearchTerm('');
											}}
										>
											See all results
										</Link>
									</Typography>
								) : (
									<Typography
										component="p"
										variant="body2"
										color="textSecondary"
										className={classes.seeAll}
									>
										No results found.
									</Typography>
								)}
							</React.Fragment>
						)}
					</Paper>
				</Grid>
			</Grid>
		</ClickAwayListener>
	);
};
