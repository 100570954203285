import React from 'react';
import { AlbumModels } from 'Models';
import { MenuItem, TextField } from '@material-ui/core';

export interface IAlbumSelectProps {
	/** The array of albums that will be shown as dropdown items */
	albums: Readonly<AlbumModels.Album[]>;
	/** The id of which item should be selected */
	selectedValue: number;
	/** Event fired when the selected item changes */
	onChange: (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the nameof generic (e.g. nameof<MyModel>('myProp')).
	 */
	propName: string;
	/** The label/placeholder for the select element */
	label?: string;
	disabled?: boolean;
	required?: boolean;
}

export const AlbumSelect = (props: IAlbumSelectProps) => {
	const required = props.required === undefined ? false : props.required;
	return (
		<TextField
			select={true}
			required={required}
			label={props.label === undefined ? 'Album' : props.label}
			disabled={props.disabled === undefined ? false : props.disabled}
			value={props.selectedValue}
			onChange={props.onChange(props.propName)}
			margin="normal"
			InputProps={{
				required: required,
				'aria-required': required,
			}}
		>
			{props.albums.map(a => (
				<MenuItem key={a.id} value={a.id}>
					{a.name}
				</MenuItem>
			))}
		</TextField>
	);
};
