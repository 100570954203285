import { useNotifications } from 'Common/Notifications';
import { Collection, CollectionDetails, PhotoCollection } from 'Models/CollectionModels';
import { Endpoint } from './Endpoint';

export const useCollectionsEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('collections');

	const Get = () =>
		ep.Get<Collection[]>().catch(() => {
			useError('Error getting collections');
			return [] as Collection[];
		});

	const GetMine = () =>
		ep.Get<Collection[]>('my').catch(() => {
			useError('Error getting my collections');
			return [] as Collection[];
		});

	const GetCollection = (id: number) =>
		ep.Get<CollectionDetails>(id).catch(() => {
			useError('Error getting collection');
			return undefined;
		});

	const Add = (name: string) =>
		ep
			.Post<Collection>({ name })
			.then(r => {
				useSuccess('Collection added');
				return r;
			})
			.catch(() => {
				useError('Error adding collection');
				return undefined;
			});

	const AddPhoto = (dto: PhotoCollection) =>
		ep
			.Post<PhotoCollection>(null, `${dto.collectionId}/photo/${dto.photoId}`)
			.then(r => {
				useSuccess('Photo added to collection');
				return r;
			})
			.catch(() => {
				useError('Error adding photo to collection');
				return undefined;
			});

	const RemovePhoto = (dto: PhotoCollection) =>
		ep
			.Delete<PhotoCollection>('', `${dto.collectionId}/photo/${dto.photoId}`)
			.then(r => {
				useSuccess('Photo removed from collection');
				return r;
			})
			.catch(() => {
				useError('Error removing photo from collection');
				return undefined;
			});

	return { Get, GetMine, GetCollection, Add, AddPhoto, RemovePhoto, IsLoading: ep.IsLoading };
};
