import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppModels, PhotoModels } from 'Models';
import { DOMHelper } from 'Common/Helpers';
import { PhotoGrid } from 'Common/Elements';
import { LinearProgress, Typography } from '@material-ui/core';
import { useSearchEndpoint } from 'Endpoints';
import { SearchResult } from 'Models/AppModels';

export const Search = (props: RouteComponentProps<any>) => {
	const searchTerm = props.match.params.term;
	const ep = useSearchEndpoint();
	const [results, setResults] = useState<SearchResult[]>([]);

	useEffect(() => {
		DOMHelper.setPageTitle('Search');

		// use the event value since using the newly set state const ended up being one step/event behind
		if (searchTerm.length >= 0) {
			// only do the search once the user's typed in at least two characters
			ep.Get(searchTerm).then(r => setResults(r));
		}
	}, []);

	if (ep.IsLoading) {
		return <LinearProgress />;
	}

	return (
		<React.Fragment>
			{/*just hide the favorite icon since it'll be involved to get that
			info for each photo when resulting stuff from the search index*/}
			{results.length ? (
				<PhotoGrid
					photos={results
						.filter(r => r.type.toLowerCase() === 'photo')
						.map(
							(r: AppModels.SearchResult) =>
								({
									id: r.id,
									title: r.title,
									url: r.url,
									isFavorited: false,
								} as PhotoModels.PhotoOverview)
						)}
					hideFavorite={true}
				/>
			) : (
				<Typography component="h1" color="textSecondary">
					No results found.
				</Typography>
			)}
		</React.Fragment>
	);
};
