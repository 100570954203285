import React, { useEffect, useState } from 'react';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { IconButton, makeStyles, Theme, createStyles, Tooltip } from '@material-ui/core';
import { usePhotosEndpoint } from 'Endpoints';

// props to be provided by parent component
// this is the components external interface
export interface IFavoriteProps {
	isFavorited: boolean;
	photoId: number;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		icon: {
			color: '#FBC02D',
		},
	})
);

export const Favorite = (props: IFavoriteProps) => {
	const classes = useStyles();
	const ep = usePhotosEndpoint();
	// allow us to toggle the icons state separate from what's passed in
	const [isFavorited, setIsFavorited] = useState(props.isFavorited);

	useEffect(() => {
		setIsFavorited(props.isFavorited);
	}, [props.isFavorited]);

	const handleAddFavorite = async () => {
		const success = await ep.AddFavorite(props.photoId);
		if (success) {
			setIsFavorited(true);
		}
	};

	const handleDeleteFavorite = async () => {
		const success = await ep.RemoveFavorite(props.photoId);
		if (success) {
			setIsFavorited(false);
		}
	};

	if (isFavorited) {
		return (
			<Tooltip title="Unfavorite">
				<IconButton className={classes.icon} onClick={handleDeleteFavorite}>
					<StarIcon />
				</IconButton>
			</Tooltip>
		);
	}

	return (
		<Tooltip title="Favorite">
			<IconButton className={classes.icon} onClick={handleAddFavorite}>
				<StarBorderIcon />
			</IconButton>
		</Tooltip>
	);
};
