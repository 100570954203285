import { useNotifications } from 'Common/Notifications';
import { AddAlbum, Album, AlbumDetails } from 'Models/AlbumModels';
import { Endpoint } from './Endpoint';

export const useAlbumsEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('albums');

	const Get = () =>
		ep.Get<Album[]>().catch(() => {
			useError('Error getting albums');
			return [] as Album[];
		});

	const GetMine = () =>
		ep.Get<Album[]>('my').catch(() => {
			useError('Error getting my albums');
			return [] as Album[];
		});

	const GetAlbum = (id: number) =>
		ep.Get<AlbumDetails>(id).catch(() => {
			useError('Error getting album');
			return undefined;
		});

	const Add = (dto: AddAlbum) =>
		ep
			.Post<number>(dto)
			.then(r => {
				useSuccess('Album added');
				return r;
			})
			.catch(() => {
				useError('Error adding album');
				return undefined;
			});

	const Delete = (id: number) =>
		ep
			.Delete<number>('', `${id}`)
			.then(r => {
				useSuccess('Album removed');
				return r;
			})
			.catch(() => {
				useError('Error removing album');
				return undefined;
			});

	return { Get, GetMine, GetAlbum, Add, Delete, IsLoading: ep.IsLoading };
};
