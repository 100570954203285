import React from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	root: {
		backgroundColor: 'white',
	},
}));

export interface ITabPanelProps {
	/** The index of the selected tab panel for this tab group */
	selectedTab: number;
	/** This tab panel's 0-based index in the list of panels */
	index: number;
}

export const TabPanel = (props: React.PropsWithChildren<ITabPanelProps>) => {
	const classes = useStyles();
	return (
		<Typography component="div" role="tabpanel" hidden={props.index !== props.selectedTab} className={classes.root}>
			<Box p={3}>{props.children}</Box>
		</Typography>
	);
};
