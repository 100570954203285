import React, { useState, useEffect } from 'react';
import { PhotoModels } from 'Models';
import { nameof } from 'Common/Helpers/ReactHelper';

import { Card, CardMedia, CardContent, TextField, CardActions, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

interface IPreviewCardProps {
	photo: PhotoModels.AddPhoto;
	onChange: (photo: PhotoModels.AddPhoto) => void;
	onDelete: (key: string) => void;
	disabled: boolean;
}

export const PreviewCard = (props: IPreviewCardProps) => {
	const [photo, setPhoto] = useState(props.photo);

	const handleChange = (k: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
		setPhoto({ ...photo, [k]: e.target.value, toFormData: photo.toFormData });
	};

	useEffect(() => {
		// put in useEffect since putting in handleChange caused this to be one step behind actual values
		// e.g. typing in 'test' in the description box would trigger onChange with 'tes' as the description value
		props.onChange(photo);
	}, [photo]);

	return (
		// stop propagation so dropzone doesn't show the open file dialog
		<Card onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
			<CardMedia image={props.photo.previewUrl} title={props.photo.title} style={{ paddingTop: '60%' }} />
			<CardContent>
				<TextField
					margin="none"
					required={true}
					fullWidth={true}
					disabled={props.disabled}
					id="title"
					label="Title"
					name="title"
					value={photo.title}
					onChange={handleChange(nameof<PhotoModels.AddPhoto>('title'))}
				/>

				<TextField
					margin="none"
					required={true}
					fullWidth={true}
					disabled={props.disabled}
					id="description"
					label="Description"
					name="description"
					value={photo.description}
					onChange={handleChange(nameof<PhotoModels.AddPhoto>('description'))}
				/>

				<TextField
					margin="none"
					fullWidth={true}
					disabled={props.disabled}
					id="credit"
					label="Credit"
					name="credit"
					value={photo.credit}
					onChange={handleChange(nameof<PhotoModels.AddPhoto>('credit'))}
				/>
			</CardContent>
			<CardActions disableSpacing={true}>
				<IconButton
					aria-label="delete"
					onClick={() => props.onDelete(props.photo.key)}
					disabled={props.disabled}
				>
					<DeleteIcon />
				</IconButton>
			</CardActions>
		</Card>
	);
};
