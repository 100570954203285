import React, { useEffect, useState } from 'react';
import { DOMHelper } from 'Common/Helpers';
import { PhotoModels, AlbumModels } from 'Models';

import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/styles';
import {
	Theme,
	Grid,
	Typography,
	Fade,
	Button,
	FormControl,
	TextField,
	Box,
	FormControlLabel,
	Checkbox,
	Switch,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import classnames from 'classnames';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { PreviewCard } from 'Photos';
import { AlbumSelect } from 'Common/Forms/Fields';
import { nameof } from 'Common/Helpers/ReactHelper';
import { useAlbumsEndpoint, usePhotosEndpoint } from 'Endpoints';
import { Album } from 'Models/AlbumModels';

const useStyles = makeStyles((theme: Theme) => ({
	dropzone: {
		display: 'flex',
		minHeight: '50vh',
		border: 'dashed 4px #ccc',
		borderRadius: 2,
	},
	accept: {
		borderColor: theme.palette.primary.main,
	},
	reject: {
		borderColor: theme.palette.error.main,
	},
	instructions: {
		textAlign: 'center',
		marginTop: '3em',
	},
	uploadIcon: {
		fontSize: 100,
		color: theme.palette.secondary.main,
	},
	preview: {
		maxWidth: '100%',
	},
	box: {
		backgroundColor: 'white',
	},
}));

export const Upload = () => {
	const classes = useStyles();
	const ep = usePhotosEndpoint();
	const albumEp = useAlbumsEndpoint();
	const [myAlbums, setMyAlbums] = useState<Album[]>([]);

	// run only when component mounts
	useEffect(() => {
		DOMHelper.setPageTitle('Upload Photos');
		albumEp.GetMine().then(r => setMyAlbums(r));
	}, []);

	const [isNewAlbum, setIsNewAlbum] = React.useState(true);
	const [photos, setPhotos] = useState<PhotoModels.AddPhoto[]>([]);
	const [albumId, setAlbumId] = useState(0);
	const [newAlbum, setNewAlbum] = useState(new AlbumModels.AddAlbum());
	const [defaultCredit, setDefaultCredit] = useState('');
	const [defaultTakenOn, setDefaultTakenOn] = useState(new Date());
	const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
		// createObjectURL should only be called here for new files. Generating on the fly results
		// in all previews being generated every render, which is super slow
		accept: 'image/jpeg, image/jpg, image/png',
		onDrop: acceptedFiles => {
			setPhotos([
				...photos,
				...acceptedFiles.map(f => {
					const p = new PhotoModels.AddPhoto();
					p.title = f.name;
					p.previewUrl = URL.createObjectURL(f);
					p.url = f.name;
					p.contents = f;
					return p;
				}),
			]);
		},
	});

	const handleNewAlbumChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewAlbum({ ...newAlbum, [key]: event.target.value });
	};

	const addPhotos = (album: number) => {
		// set album-wide values for each photo
		photos.forEach(p => {
			p.albumId = album;
			p.credit = p.credit === '' ? defaultCredit : p.credit;
			p.takenOn = p.takenOn === undefined ? defaultTakenOn : p.takenOn;
		});
		// add the photos and clear the form if that succeeds
		ep.Add(photos).then(success => success === photos.length && clearForm());
	};

	const handlePhotoChange = (updatedPhoto: PhotoModels.AddPhoto) => {
		// update the photos array so the affected photo has its new values
		setPhotos(photos.map(p => (p.key !== updatedPhoto.key ? p : updatedPhoto)));
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		// create a new album if the create tab is selected
		if (isNewAlbum) {
			albumEp.Add(newAlbum).then(newId => newId && addPhotos(newId));
		} else {
			// tie photos to an existing album if that tab is selected
			addPhotos(albumId);
		}
	};

	const clearForm = () => {
		// new album
		setNewAlbum(new AlbumModels.AddAlbum());
		// existing album
		setAlbumId(0);
		// photos
		setPhotos([]);
	};

	return (
		<Fade in={true}>
			<form onSubmit={handleSubmit}>
				<Grid container={true} spacing={2}>
					<Grid item={true} md={3} lg={2}>
						<Box p={3} className={classes.box}>
							<FormControlLabel
								control={
									<Switch
										checked={isNewAlbum}
										onChange={() => setIsNewAlbum(!isNewAlbum)}
										name="newAlbum"
									/>
								}
								label="Add New Album"
							/>

							{isNewAlbum ? (
								<>
									<Typography component="p" color="textSecondary">
										Add photos to a new album
									</Typography>
									<FormControl>
										<TextField
											label="Name"
											required={isNewAlbum}
											disabled={ep.IsLoading}
											value={newAlbum.name}
											onChange={handleNewAlbumChange(nameof<AlbumModels.AddAlbum>('name'))}
										/>
									</FormControl>
									<FormControl>
										<TextField
											label="Description"
											required={isNewAlbum}
											disabled={ep.IsLoading}
											value={newAlbum.description}
											onChange={handleNewAlbumChange(nameof<AlbumModels.AddAlbum>('description'))}
										/>
									</FormControl>
									<FormControl>
										<FormControlLabel
											control={
												<Checkbox
													checked={newAlbum.isShared}
													onChange={(e, checked) =>
														setNewAlbum({ ...newAlbum, isShared: checked })
													}
													value={newAlbum.isShared}
												/>
											}
											label="Allow other users to add to this album"
										/>
									</FormControl>
								</>
							) : (
								<>
									<Typography component="p" color="textSecondary">
										Add photos to one of your existing albums
									</Typography>
									<FormControl>
										<AlbumSelect
											albums={myAlbums}
											selectedValue={albumId}
											disabled={ep.IsLoading}
											required={!isNewAlbum}
											propName={nameof<AlbumModels.Album>('id')}
											onChange={() => (event: React.ChangeEvent<HTMLInputElement>) =>
												setAlbumId(Number.parseInt(event.target.value, 10))}
										/>
									</FormControl>
								</>
							)}
						</Box>
						<Box p={3} className={classes.box}>
							<Typography gutterBottom={true} variant="h6" component="h2">
								Photo Defaults
							</Typography>
							<Typography component="p" color="textSecondary">
								Set default values for photos. Any values you set for specific photos will override the
								below values.
							</Typography>

							<FormControl>
								<TextField
									label="Credit"
									disabled={ep.IsLoading}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										setDefaultCredit(event.target.value);
									}}
								/>
							</FormControl>
							<FormControl>
								<KeyboardDatePicker
									showTodayButton={true}
									disabled={ep.IsLoading}
									id="date-picker-dialog"
									label="Date Taken"
									format="MM/dd/yyyy"
									value={defaultTakenOn}
									onChange={(date: Date) => setDefaultTakenOn(date)}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
								/>
							</FormControl>
							<FormControl>
								<Button
									type="submit"
									color="primary"
									variant="contained"
									disabled={ep.IsLoading || !photos.length}
								>
									Add
								</Button>
							</FormControl>
						</Box>
					</Grid>
					<Grid
						item={true}
						md={9}
						lg={10}
						{...getRootProps()}
						className={classnames(
							classes.dropzone,
							{ [classes.accept]: isDragAccept },
							{ [classes.reject]: isDragReject }
						)}
					>
						<input {...getInputProps()} />
						{!photos.length ? (
							<Grid container={true} className={classes.instructions} spacing={1}>
								<Grid item={true} xs={12} lg={12}>
									<CloudUpload className={classes.uploadIcon} />
									<Typography component="p" variant="h4">
										Drag 'n' drop some photos here
									</Typography>
									<Typography component="p" variant="h6" color="textSecondary">
										or click to select photos
									</Typography>
								</Grid>
							</Grid>
						) : (
							<Grid container={true} spacing={2}>
								{photos.map(photo => (
									<Grid item={true} sm={6} md={3} lg={2} key={photo.key}>
										<PreviewCard
											photo={photo}
											onChange={handlePhotoChange}
											disabled={ep.IsLoading}
											onDelete={(key: string) => setPhotos(photos.filter(p => p.key !== key))}
										/>
									</Grid>
								))}
							</Grid>
						)}
					</Grid>
				</Grid>
			</form>
		</Fade>
	);
};
