import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import React from 'react';
import { uppercaseFirst } from 'Common/Helpers/TextHelper';

export interface IDeleteConfirmProps {
	/** The name of the item to be deleted (i.e. Obligation, Partner, etc.) */
	label: string;
	/** A boolean value that determines if the modal is open or not */
	open: boolean;
	/** Should be function in the calling component that will handle the delete */
	handleDelete: any;
	/** Passes onClose function value from the calling component */
	closeModal: any;
	warningText?: string;
}
export const DeleteConfirmation = (props: IDeleteConfirmProps) => {
	return (
		<Dialog maxWidth="xs" open={props.open} onClose={() => props.closeModal()} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Delete {uppercaseFirst(props.label)}</DialogTitle>
			<DialogContent>
				{props.warningText || `Are you sure you want to delete this ${props.label.toLowerCase()}?`}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						props.closeModal();
					}}
					color="default"
				>
					No
				</Button>
				<Button
					onClick={() => {
						props.closeModal();
						props.handleDelete();
					}}
				>
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	);
};
