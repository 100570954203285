import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DOMHelper } from 'Common/Helpers';

export const Home = () => {
	useEffect(() => {
		DOMHelper.setPageTitle('Photos');
	}, []);

	return (
		<div>
			I'm the photos page. <Link to="/photos/1">Link to photo details</Link>
		</div>
	);
};
