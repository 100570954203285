import { format, parse, parseISO, toDate } from 'date-fns';

export const getDateString = (date: Date): string => {
	const defaultDateValue = '0001-01-01T00:00:00';
	const defaultDate = new Date(Date.parse(defaultDateValue));

	if (date.valueOf() === defaultDate.valueOf()) {
		return defaultDate.toDateString();
	}

	return date.toDateString();
};

/** Returns the given date in M/d/yyyy format
 * @param {Date?} date An actual data object to be formatted
 */
export const getShortDateString = (date: Date | undefined | null): string => {
	if (date === undefined || date === null) {
		return '';
	}

	// seems that values from a datepicker want toDate, but ISO dates returned from API want parseISO
	try {
		// try to parse the date if it's in ISO format
		return format(parseISO(date.toString()), 'M/d/yyyy');
	} catch {
		// just swallow so things will continue
	}

	// just make into a string and parse again since some dates were not actual date objects. I have no answers
	return format(toDate(date), 'M/d/yyyy');
};

/** Attempts to parse a date string in M/d/yyyy format */
export const parseShortDate = (dateString: string | undefined | null): Date | undefined => {
	if (dateString === undefined || dateString === null) {
		return undefined;
	}

	return parse(dateString, 'M/d/yyyy', new Date());
};

export const getDateTimeString = (date: Date): string => {
	const defaultDateValue = '0001-01-01T00:00:00';
	const defaultDate = new Date(Date.parse(defaultDateValue));

	if (date.valueOf() === defaultDate.valueOf()) {
		return `${defaultDate.toDateString()} ${defaultDate.toLocaleTimeString()}`;
	}

	return `${date.toDateString()} ${date.toLocaleTimeString()}`;
};

export const calculateDaysDifference = (date1: Date, date2: Date): number => {
	const millisecondsInADay = 1000 * 60 * 60 * 24;
	const date1InMS = date1.getTime();
	const date2InMS = date2.getTime();
	const difference = date2InMS - date1InMS;
	return Math.round(difference / millisecondsInADay);
};

// to be used for converting dates to ISO strings in local time.
export const toLocalISOString = (date: Date): string => {
	return date.toISOString().slice(0, -1); // cut the Z off the end so it's not in UTC time.
};
