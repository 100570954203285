import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import { Theme, Hidden } from '@material-ui/core';
import { Search } from './Search';
import settings from 'settings';

interface IAppBarProps {
	menuClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	barContainer: {
		zIndex: theme.zIndex.drawer + 1,
	},
	nav: {
		marginLeft: 48,
	},
	navLink: {
		marginLeft: 8,
		marginRight: 8,
	},
	grow: {
		flexGrow: 1,
	},
	title: {
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},
}));

export const TopAppBar = (props: IAppBarProps) => {
	const classes = useStyles();

	return (
		<div className={classes.barContainer}>
			<AppBar position="fixed">
				<Toolbar>
					<Hidden mdUp={true} implementation="css">
						<IconButton color="inherit" aria-label="Open drawer" onClick={props.menuClick}>
							<MenuIcon />
						</IconButton>
					</Hidden>
					<Typography className={classes.title} variant="h6" color="inherit" noWrap={true}>
						<Link to="/">{settings.siteName}</Link>
					</Typography>
					<Hidden smDown={true} implementation="css">
						<div className={classes.nav}>
							<Link className={classes.navLink} to="/">
								Home
							</Link>
							<Link className={classes.navLink} to="/albums">
								Albums
							</Link>
							<Link className={classes.navLink} to="/collections">
								Collections
							</Link>
							<Link className={classes.navLink} to="/photos/favorites">
								My Favorites
							</Link>
							{/* <Link className={classes.navLink} to="/users">
							Users
						</Link> */}
							<Link className={classes.navLink} to="/upload">
								Upload
							</Link>
						</div>
					</Hidden>

					<div className={classes.grow} />
					<Search />
				</Toolbar>
			</AppBar>
		</div>
	);
};

export default TopAppBar;
