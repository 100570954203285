import React, { useEffect, useState } from 'react';
import { RouteComponentProps, Route } from 'react-router';
import { DOMHelper, DateHelper } from 'Common/Helpers';
import { CollectionModels } from 'Models';

import {
	makeStyles,
	Theme,
	Grid,
	Paper,
	Typography,
	Divider,
	Tooltip,
	IconButton,
	Fade,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	LinearProgress,
} from '@material-ui/core';
import OwnerIcon from '@material-ui/icons/AccountCircleRounded';
import TodayIcon from '@material-ui/icons/CalendarToday';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { Favorite } from 'Photos';
import { SelectCollectionForm, AddCollectionForm } from 'Common/Forms';
import { Link } from 'react-router-dom';
import { useCollectionsEndpoint, usePhotosEndpoint } from 'Endpoints';
import { PhotoDetails } from 'Models/PhotoModels';
import { Collection } from 'Models/CollectionModels';
import { EditIcon } from 'Common/Elements';
import { EditPhotoForm } from 'Photos/Forms';
import { useAppState } from 'Context/AppProvider';

enum ModalOptions {
	None = 0,
	AddCollection = 1,
	EditPhoto = 2,
}

const useStyles = makeStyles((theme: Theme) => ({
	img: {
		maxWidth: '100%',
		marginBottom: '-5px',
	},
	title: {
		fontSize: '48px',
		marginBottom: theme.spacing(2),
	},
	icon: {
		position: 'relative',
		top: '6px',
		marginRight: theme.spacing(0.5),
	},
	divider: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	iconButton: {
		pointer: 'cursor',
		'&:hover,&:active': {
			color: '#0084CC',
		},
	},
	favoriteIcon: {
		pointer: 'cursor',
		color: 'rgba(0, 0, 0, 0.54)',
		'&:hover,&:active': {
			color: '#FF1A28',
		},
	},
	favoriteActivatedIcon: {
		color: '#FF1A28',
		pointer: 'cursor',
		'&:hover,&:active': {
			color: 'rgba(0, 0, 0, 0.54)',
		},
	},
}));

export const Details = (props: RouteComponentProps<any>) => {
	const state = useAppState();
	const classes = useStyles();
	const ep = usePhotosEndpoint();
	const collectionsEp = useCollectionsEndpoint();
	const [collections, setCollections] = useState<Collection[]>([]);

	const [openModal, setOpenModal] = useState<ModalOptions>(ModalOptions.None);
	const [photo, setPhoto] = useState<PhotoDetails>(new PhotoDetails());

	useEffect(() => {
		DOMHelper.setPageTitle('Photo Details');
		collectionsEp.Get().then(r => setCollections(r));
		ep.GetPhoto(props.match.params.id).then(r => r && setPhoto(r));
	}, []);

	useEffect(() => {
		if (photo.title !== '') {
			DOMHelper.setPageTitle(photo.title);
		}
	}, [photo]);

	const submitModalSelectCollectionForm = async (data: CollectionModels.PhotoCollection) => {
		collectionsEp.AddPhoto(data).then(
			r =>
				r &&
				// add the collection to the list of 'Photo belongs to collections'
				setPhoto({
					...photo,
					collections: [...photo.collections, { id: r.collectionId, name: r.collectionName }],
				})
		);
	};

	const handleAddCollectionSubmit = (name: string) => {
		collectionsEp.Add(name).then(r => r && setOpenModal(ModalOptions.None));
	};

	const downloadPhoto = () => {
		ep.Download(photo.id);
	};

	if (!state.CurrentUser) {
		return null;
	}

	if (ep.IsLoading || collectionsEp.IsLoading) {
		return <LinearProgress />;
	}

	return (
		<Fade in={true}>
			<React.Fragment>
				<Grid container={true} spacing={2}>
					<Grid item={true} xs={12} md={8}>
						<Paper>
							<img className={classes.img} src={photo.url + '/1300'} alt={photo.title} />
						</Paper>
					</Grid>
					<Grid item={true} xs={12} md={4}>
						<Typography variant="h2" className={classes.title}>
							{photo.title}
						</Typography>
						{photo.credit && (
							<Typography variant="subtitle1">
								<OwnerIcon className={classes.icon} />
								<strong>Credit:</strong> {photo.credit}
							</Typography>
						)}

						<Typography variant="subtitle1">
							<TodayIcon className={classes.icon} />
							<strong>Taken:</strong> {DateHelper.getShortDateString(photo.takenOn)}
						</Typography>

						<Divider className={classes.divider} />
						{photo.description ? (
							<React.Fragment>
								{photo.description}
								<Divider className={classes.divider} />
							</React.Fragment>
						) : (
							''
						)}
						{photo.collections.length ? (
							<React.Fragment>
								<Typography variant="subtitle1">Belongs to Collections</Typography>
								{photo.collections.map(c => (
									<Link key={c.id} to={'/collections/' + c.id} style={{ display: 'block' }}>
										{c.name}
									</Link>
								))}
								<Divider className={classes.divider} />
							</React.Fragment>
						) : (
							''
						)}

						<Tooltip title="View/Download Original">
							<IconButton className={classes.iconButton} onClick={downloadPhoto}>
								<DownloadIcon />
							</IconButton>
						</Tooltip>
						{/* wrap this in a route so we can push to history.
						did this instead of using a Link component to avoid weird styling */}
						<Route
							render={({ history }) => (
								<Tooltip title="View album">
									<IconButton
										className={classes.iconButton}
										onClick={() => history.push('/albums/' + photo.albumId)}
									>
										<PhotoAlbumIcon />
									</IconButton>
								</Tooltip>
							)}
						/>
						<SelectCollectionForm
							collections={collections}
							photoId={photo.id}
							onSubmit={submitModalSelectCollectionForm}
							onNewClick={() => setOpenModal(ModalOptions.AddCollection)}
						/>
						<Favorite isFavorited={photo.isFavorited} photoId={photo.id} />
						{[photo.albumOwner, photo.createdBy].includes(state.CurrentUser.guid) && (
							<Tooltip title="Edit Photo">
								<IconButton
									className={classes.iconButton}
									onClick={() => setOpenModal(ModalOptions.EditPhoto)}
								>
									<EditIcon />
								</IconButton>
							</Tooltip>
						)}
					</Grid>
				</Grid>

				<Dialog
					maxWidth="xs"
					open={openModal === ModalOptions.AddCollection}
					onClose={() => setOpenModal(ModalOptions.None)}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">Add New Collection</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<AddCollectionForm onSubmit={handleAddCollectionSubmit} />
						</DialogContentText>
					</DialogContent>
				</Dialog>

				<Dialog
					maxWidth="xs"
					open={openModal === ModalOptions.EditPhoto}
					onClose={() => setOpenModal(ModalOptions.None)}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">Edit Photo</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<EditPhotoForm
								photo={photo}
								onSuccess={r => {
									setPhoto(r);
									setOpenModal(ModalOptions.None);
								}}
							/>
						</DialogContentText>
					</DialogContent>
				</Dialog>
			</React.Fragment>
		</Fade>
	);
};
