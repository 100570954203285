import { useNameof } from 'Common/Helpers/ReactHelper';
import { usePhotosEndpoint } from 'Endpoints';
import { EditPhoto, PhotoDetails } from 'Models/PhotoModels';
import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button, FormControl, Grid } from '@material-ui/core';
import { EditPhotoSchema } from './ValidationSchemas';
import { GlobalDatePicker } from 'Common/Forms/Fields';

export interface IEditPhotoFormProps {
	photo: PhotoDetails;
	onSuccess: (details: PhotoDetails) => void;
}

export const EditPhotoForm = (props: IEditPhotoFormProps) => {
	const ep = usePhotosEndpoint();
	const nameof = useNameof<EditPhoto>();

	return (
		<Formik
			initialValues={new EditPhoto(props.photo)}
			validationSchema={EditPhotoSchema}
			onSubmit={(values, { setSubmitting }) => {
				ep.Edit(values).then(response => response && props.onSuccess(response));
				setSubmitting(false);
			}}
		>
			{formprops => (
				<Form>
					<Grid container={true} direction="row">
						<FormControl>
							<Field component={TextField} label="Title" fullWidth={true} name={nameof('title')} />
						</FormControl>
						<FormControl>
							<Field
								component={TextField}
								label="Description"
								fullWidth={true}
								name={nameof('description')}
							/>
						</FormControl>
						<FormControl>
							<Field component={TextField} label="Credit" fullWidth={true} name={nameof('credit')} />
						</FormControl>
						<FormControl>
							<Field component={GlobalDatePicker} label="Taken On" name={nameof('takenOn')} />
						</FormControl>
						<FormControl>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								disabled={formprops.isSubmitting}
								onSubmit={formprops.submitForm}
							>
								{formprops.isSubmitting ? 'Saving...' : 'Save'}
							</Button>
						</FormControl>
					</Grid>
					{/* {<pre>{JSON.stringify(formprops, null, 2)}</pre>} */}
				</Form>
			)}
		</Formik>
	);
};
