import React, { useState } from 'react';
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router';
import classnames from 'classnames';

import AppBar from 'Layout/Navigation/AppBar';
import * as Pages from 'Pages';
import * as AlbumPages from 'Albums/Pages';
import * as CollectionPages from 'Collections/Pages';
import * as UserPages from 'Pages/Users';
import * as PhotoPages from 'Photos/Pages';
import * as AdminPages from 'Pages/Admin';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import {
	Theme,
	CssBaseline,
	Divider,
	IconButton,
	Drawer,
	Hidden,
	List,
	ListItem,
	ListItemText,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link } from 'react-router-dom';
import { SignInManager } from 'Common/Auth';

interface ILayoutProps {
	isLoading: boolean;
}

const drawerWidth = 240;

const contentTheme = {
	props: {
		MuiFormControl: {
			fullWidth: true,
		},
	},
	overrides: {
		MuiPaper: {
			elevation1: {
				padding: 8,
				margin: 8,
			},
		},
		MuiExpansionPanelSummary: {
			root: {
				'&$expanded': {
					borderBottom: '1px solid rgba(0, 0, 0, .125)',
				},
			},
		},
		MuiTypography: {
			h5: {
				marginTop: 8,
				marginBottom: 8,
			},
		},
		MuiFab: {
			root: {
				margin: 0,
				top: 'auto',
				right: 20,
				bottom: 20,
				left: 'auto',
				position: 'fixed' as const,
			},
		},
		MuiFormControl: {
			root: {
				margin: 4,
				minWidth: 120,
			},
		},
	},
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		height: '100%',
	},
	toolbar: theme.mixins.toolbar,
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		zIndex: 1000,
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100%',
		overflow: 'auto',
	},
	padContent: {
		padding: theme.spacing(3),
	},
	pageLinks: {
		textDecoration: 'none',
	},
}));

export const Layout: React.FunctionComponent<ILayoutProps & RouteComponentProps<any>> = props => {
	const classes = useStyles();
	// will be used to remove padding from just the home page
	const isHome = props.location.pathname === '/';
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar menuClick={() => setIsDrawerOpen(!isDrawerOpen)} />
			<Hidden smUp={true} implementation="css">
				<Drawer
					variant="temporary"
					anchor="left"
					ModalProps={{ onBackdropClick: () => setIsDrawerOpen(false) }}
					classes={{
						paper: classnames('page-links', classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose),
					}}
					open={isDrawerOpen}
				>
					<div className={classes.toolbarIcon}>
						<IconButton onClick={() => setIsDrawerOpen(false)}>
							<ChevronLeftIcon />
						</IconButton>
					</div>
					<Divider />
					<List>
						<Link to="/" className={classes.pageLinks} onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
							<ListItem button={true}>
								<ListItemText primary="Home" />
							</ListItem>
						</Link>
						<Link to="/albums" className={classes.pageLinks} onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
							<ListItem button={true}>
								<ListItemText primary="Albums" />
							</ListItem>
						</Link>
						<Link
							to="/collections"
							className={classes.pageLinks}
							onClick={() => setIsDrawerOpen(!isDrawerOpen)}
						>
							<ListItem button={true}>
								<ListItemText primary="Collections" />
							</ListItem>
						</Link>
						<Link
							to="/photos/favorites"
							className={classes.pageLinks}
							onClick={() => setIsDrawerOpen(!isDrawerOpen)}
						>
							<ListItem button={true}>
								<ListItemText primary="My Favorites" />
							</ListItem>
						</Link>
						<Link to="/upload" className={classes.pageLinks} onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
							<ListItem button={true}>
								<ListItemText primary="Upload" />
							</ListItem>
						</Link>
					</List>
				</Drawer>
			</Hidden>
			<ThemeProvider theme={overallTheme => ({ ...overallTheme, ...contentTheme })}>
				<main className={classnames(classes.content, { [classes.padContent]: !isHome })}>
					<div className={classes.appBarSpacer} />
					<SignInManager>
						<Switch>
							<Route path="/" exact={true} component={Pages.Home} />

							<Route path="/photos" exact={true} component={PhotoPages.Home} />
							<Route path="/photos/favorites" exact={true} component={PhotoPages.Favorites} />
							<Route path="/photos/:id" component={PhotoPages.Details} />
							<Route path="/upload" component={PhotoPages.Upload} />

							<Route path="/albums" exact={true} component={AlbumPages.Home} />
							<Route path="/albums/:id" component={AlbumPages.Details} />

							<Route path="/collections" exact={true} component={CollectionPages.Home} />
							<Route path="/collections/all" exact={true} component={CollectionPages.All} />
							<Route path="/collections/:id" component={CollectionPages.Details} />

							<Route path="/users" exact={true} component={UserPages.Home} />

							<Route path="/search/:term" component={Pages.Search} />

							<Route path="/admin/search" exact={true} component={AdminPages.SearchAdmin} />
						</Switch>
					</SignInManager>
				</main>
			</ThemeProvider>
		</div>
	);
};

export default withRouter(Layout);
