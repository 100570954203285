import React from 'react';
import Edit from '@material-ui/icons/Edit';
import { makeStyles, Theme } from '@material-ui/core';

export interface IEditIconProps {
	onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		color: theme.palette.warning.main,
	},
}));

export const EditIcon = (props: IEditIconProps) => {
	const classes = useStyles();

	return <Edit className={classes.root} onClick={props.onClick} />;
};
