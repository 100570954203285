import React from 'react';
import { MenuItem, Menu, IconButton, Tooltip } from '@material-ui/core';
import { CollectionModels } from 'Models';
import AddCollectionIcon from '@material-ui/icons/AddToPhotos';

interface ISelectCollectionFormProps {
	onSubmit: (data: CollectionModels.PhotoCollection) => void;
	collections: ReadonlyArray<CollectionModels.Collection>;
	photoId: number;
	onNewClick: () => void;
}

export const SelectCollectionForm: React.FunctionComponent<ISelectCollectionFormProps> = props => {
	const [anchorEl, setAnchorEl] = React.useState<Element | undefined>(undefined);

	const handleItemClick = (collectionId: number) => {
		const data: CollectionModels.PhotoCollection = {
			photoId: props.photoId,
			collectionId: collectionId,
			collectionName: '',
		};
		props.onSubmit(data);
		setAnchorEl(undefined);
	};

	const handleAnchorClick = (event: React.MouseEvent) => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<React.Fragment>
			<Tooltip title="Add to Collection">
				<IconButton onClick={handleAnchorClick}>
					<AddCollectionIcon />
				</IconButton>
			</Tooltip>
			<Menu
				id="collection-menu"
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(undefined)}
			>
				{props.collections.map(c => (
					<MenuItem onClick={() => handleItemClick(c.id)} key={c.id}>
						{c.name}
					</MenuItem>
				))}
				<MenuItem
					onClick={() => {
						props.onNewClick();
						setAnchorEl(undefined);
					}}
				>
					Add New
				</MenuItem>
			</Menu>
		</React.Fragment>
	);
};
