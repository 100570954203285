import React, { FormEvent, useState } from 'react';
import { Grid, TextField, FormControl, Button } from '@material-ui/core';

export interface IAddCollectionFormProps {
	onSubmit: (title: string) => void;
}

export const AddCollectionForm = (props: IAddCollectionFormProps) => {
	const [name, setName] = useState('');
	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		props.onSubmit(name);
	};

	return (
		<form id="add-collection-form" onSubmit={handleSubmit}>
			<Grid container={true} direction="column" justify="flex-start" spacing={3}>
				<Grid item={true} xs={12}>
					<FormControl>
						<TextField
							label="Name"
							required={true}
							value={name}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
						/>
					</FormControl>
					<FormControl>
						<Button type="submit" color="primary" variant="contained">
							Add
						</Button>
					</FormControl>
				</Grid>
			</Grid>
		</form>
	);
};

export default AddCollectionForm;
