import React, { useEffect } from 'react';
import { DOMHelper } from 'Common/Helpers';
import { Fade } from '@material-ui/core';

export const Home = () => {
	useEffect(() => {
		DOMHelper.setPageTitle('Users');
	}, []);

	return (
		<Fade in={true}>
			<p>
				I'm the users page. We'll show users (that have submitted photos) with their most popular photo, along
				with a photo count and number of favorites.
			</p>
		</Fade>
	);
};
