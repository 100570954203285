import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { DOMHelper } from 'Common/Helpers';
import { PhotoGrid } from 'Common/Elements';
import { useCollectionsEndpoint } from 'Endpoints';
import { CollectionDetails } from 'Models/CollectionModels';
import { LinearProgress } from '@material-ui/core';

export const Details = (props: RouteComponentProps<any>) => {
	const ep = useCollectionsEndpoint();
	const [collection, setCollection] = useState<CollectionDetails>();

	useEffect(() => {
		DOMHelper.setPageTitle('Collection Details');
		ep.GetCollection(props.match.params.id).then(r => r && setCollection(r));
	}, []);

	useEffect(() => {
		if (collection && collection.name !== '') {
			DOMHelper.setPageTitle(collection.name);
		}
	}, [collection]);

	if (ep.IsLoading || !collection) {
		// TODO: make this look nicer
		return <LinearProgress />;
	}

	return (
		<React.Fragment>
			<h2>{collection.name}</h2>
			<PhotoGrid photos={collection.photos} />
		</React.Fragment>
	);
};
