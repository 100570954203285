import { DateHelper } from 'Common/Helpers';
import cuid from 'cuid';

/** used as the input type to a photo grid */
export class PhotoOverview {
	id = 0;
	title = '';
	isFavorited = false;
	/** The full Url to the photo's contents */
	url = '';
}

export class PhotoDetails {
	id = 0;
	title = '';
	description = '';
	credit = '';
	albumId = 0;
	userName = '';
	createdBy = '';
	albumOwner = '';
	submittedAt: Date = new Date();
	takenOn: Date = new Date();
	favoritesCount = 0;
	isFavorited = false;
	/** The full Url to the photo's contents */
	url = '';
	collections: Array<{
		id: number;
		name: string;
	}> = [];
}

export class AddPhoto {
	// named it key so model binding wouldn't pick it up
	// this is used for updating and removing photos from arrays
	// since they don't have ids from the database at this point
	key: string = cuid();
	title = '';
	description = '';
	credit = '';
	previewUrl = '';
	albumId = 0;
	takenOn?: Date;
	url = '';
	contents?: Blob;

	toFormData() {
		const formData = new FormData();

		formData.append('title', this.title);
		formData.append('description', this.description);
		formData.append('credit', this.credit);
		formData.append('albumId', this.albumId.toString());
		formData.append('takenOn', DateHelper.getShortDateString(this.takenOn));
		this.contents !== undefined && formData.append('contents', this.contents, this.url);

		return formData;
	}
}

export class EditPhoto {
	id: number;
	title: string;
	description: string;
	credit: string;
	takenOn: Date;

	constructor(details: PhotoDetails) {
		this.id = details.id;
		this.title = details.title;
		this.description = details.description;
		this.credit = details.credit;
		this.takenOn = details.takenOn;
	}
}
