import React, { useEffect } from 'react';
import { Paper, Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import { DOMHelper } from 'Common/Helpers';
import { useSearchEndpoint } from 'Endpoints';

export const SearchAdmin = () => {
	const ep = useSearchEndpoint();

	useEffect(() => {
		DOMHelper.setPageTitle('Search Admin');
	}, []);

	return (
		<Grid container={true}>
			<Grid item={true} md={6} lg={4}>
				<Paper>
					<Typography gutterBottom={true} variant="h5" component="h2">
						Build Search Index
					</Typography>
					<Typography variant="body2" color="textSecondary" component="div">
						<p>
							Click the button below to rebuild the entire search index for projects, agreements, and
							parcels. This action may take a few minutes.
						</p>
					</Typography>
					<Button variant="contained" color="primary" disabled={ep.IsLoading} onClick={() => ep.Build()}>
						{ep.IsLoading && (
							<CircularProgress
								size={24}
								style={{
									position: 'absolute',
									top: '50%',
									left: '50%',
									marginTop: -12,
									marginLeft: -12,
								}}
							/>
						)}
						Rebuild Index
					</Button>
				</Paper>
			</Grid>
		</Grid>
	);
};
