import * as yup from 'yup';
import isValid from 'date-fns/isValid';

/** Schema for a required number that must be greater than or equal to zero or the provided minimum number */
export const numberSchema = (label: string, min = 0) =>
	yup
		.number()
		.typeError(`${label} must be a number`)
		.required(`${label} is required`)
		.min(min, `${label} must be ${min} or above`);

/** Schema for a required percent that must be between 0 and 100 */
export const percentSchema = (label: string) =>
	yup
		.number()
		.typeError(`${label} must be a number`)
		.required(`${label} is required`)
		.min(0, `${label} must be 0 or above`)
		.max(100, `${label} must be 100 or below`);

/** Schema for a required string */
export const stringSchema = (label: string, maxLength = 99999) =>
	yup.string().required(`${label} is required`).max(maxLength);

/** Schema for a string that can't be undefined. Will still allow an empty string */
export const emptyStringSchema = (label: string) => yup.string().ensure().defined();

/** Schema for a required select that has a number id */
export const selectSchema = (label: string, min = 1) =>
	yup
		.number()
		.typeError(`${label} must be a number`)
		.required(`${label} is required`)
		.min(min, `${label} must have a selection`);

const dateTransform = (curVal: any) => (isValid(curVal) ? curVal : undefined);

export const dateSchema = (label: string) =>
	yup.date().transform(dateTransform).required(`${label} is required`).typeError(`${label} is required`);
