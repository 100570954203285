import React, { useEffect, useState } from 'react';
import { Grid, Fade, makeStyles, Theme, InputBase } from '@material-ui/core';
import { DOMHelper } from 'Common/Helpers';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import settings from 'settings';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		height: 'calc(100% - 64px)', // subtract 64px for height of nav bar
		backgroundImage: 'url(https://source.unsplash.com/1dGMs4hhcVA/1920x900)',
		backgroundSize: 'cover',
	},
	gridItem: {
		textAlign: 'center',
	},
	welcome: {
		color: 'white',
		textAlign: 'center',
	},
	inputRoot: {
		color: 'inherit',
		width: '90%',
		backgroundColor: 'white',
		opacity: 0.85,
		margin: '0 auto',
	},
	inputInput: {
		padding: theme.spacing(2),
		width: '100%',
	},
}));

export const Home = (props: RouteComponentProps<any>) => {
	const classes = useStyles();
	const [searchTerm, setSearchTerm] = useState('');
	useEffect(() => {
		DOMHelper.setPageTitle('Home');
	}, []);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		console.log('search form submitted with value', searchTerm);
		if (searchTerm !== '') {
			props.history.push('/search/' + searchTerm);
		}
	};

	return (
		<Fade in={true}>
			<Grid container={true} spacing={0} className={classes.root}>
				<Grid item={true} md={2} lg={3} />
				<Grid item={true} md={8} lg={6} className={classes.gridItem}>
					<h1 className={classes.welcome}>
						Welcome to {settings.siteName}, your source for quality, relevant natural resource photos
					</h1>
					<Grid container={true}>
						<Grid item={true} xs={12}>
							<form onSubmit={handleSubmit}>
								<InputBase
									placeholder="Search…"
									onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
										setSearchTerm(event.target.value)
									}
									classes={{
										root: classes.inputRoot,
										input: classes.inputInput,
									}}
								/>
							</form>
						</Grid>
					</Grid>
				</Grid>
				<Grid item={true} md={2} lg={3} />
			</Grid>
		</Fade>
	);
};

export default withRouter(Home);
